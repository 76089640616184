
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Modal } from "bootstrap";
import { number } from "yup/lib/locale";
import Products from "@/views/apps/product/Products.vue";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";

export default defineComponent({
  name: "edit-categories-modal", 
  props: {
    data: {
        type: Object
    }
  },
  components: {    
    },   
  methods: {
      onChange() 
      { 
        this.formData['category_select'] = '' 
        }
  },   
  setup(props) {    

    // this.$forceUpdate();
    const router = useRouter();    
    var formData = ref({})
    const store = useStore();
    const setFormData = async (data) => {    
        var j = 0;

        try {

        var values = { "price_template_id": data.ids }
         await store.dispatch(Actions.CUST_PRICE_EDIT, values).then(({ data }) => {       
          formData.value = {
            id: data.price_template_id,
            name: data.template_name,
            category_select: data.category_id,
            parent_select: data.parent_category_id,
            city_select: data.city_id,
            value_select: data.currency_id,
            incoterm_select:data.incoterm_id,
            delivery_select: data.delivery_term_id,
            active : true,
            };
          console.log(formData.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }

    const incoterm = ref([]);
    const setIncotermData = async () => {
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_PRICE_INCOTERM).then(({ data }) => {
          incoterm.value = data;
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const delivery = ref([]);
    const setDeliveryData = async () => {
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_PRICE_DELIVERY_TERM).then(({ data }) => {
          delivery.value = data;
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const city = ref([]);
    const setCityData = async () => {
       // loading.value = true;
        try {
          await store.dispatch(Actions.CUST_CITY_LIST).then(({ data }) => {
          city.value = data;
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const currency = ref([]);
    const setCurrencyData = async () => {
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_PRICE_CURRENCY).then(({ data }) => {
          currency.value = data;
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const parentcat = ref([]);
     const setParentCatData = async () => {
        loading.value = true;
        try {
          var values = {}
          await store.dispatch(Actions.CUST_NEWS_PARENT_CATEGORY, values).then(({ data }) => {
          parentcat.value = data;
          loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const childcat = ref([]);
     const setChildCatData = async () => {
      
        loading.value = true;
        try {
          var values = {
            "category_id": formData.value['parent_select']
            }
          await store.dispatch(Actions.CUST_NEWS_CHILD_CATEGORY, values).then(({ data }) => {
          childcat.value = data;
          loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    onMounted( async () => {      
      await setFormData(props.data);
      await setIncotermData()
      await setDeliveryData()
      await setCurrencyData()
      await setParentCatData()
      await setChildCatData()
      await setCityData()
      });  

    
    const formRef = ref<null | HTMLFormElement>(null);
    const editPriceModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);    

    const rules = ref({
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",  
             
        },  
      ],   
      city_select: [
        {
          required: true,
          message: "City is required",
          trigger: "change",  
        },  
      ],   
      parent_select: [
        {
          required: true,
          trigger: "change",  
        }
      ],
      category_select: [
        {
          required: true,
          message: "Category is required",
          trigger: "change",  
        },  
      ],  
      value_select: [
        {
          required: true,
          message: "Currency is required",
          trigger: "change",  
        },  
      ], 
      incoterm_select: [
        {
          required: true,
          message: "Incoterm is required",
          trigger: "change",  
        },  
      ], 
      delivery_select: [
        {
          required: true,
          message: "Delivery is required",
          trigger: "change",  
        },  
      ], 
    });

   


    const setPriceData = async (data) => {   
      //var user = JSON.parse(JwtService.getToken());       
      const db_data = {
        "price_template_id": data.id,
        "template_name" : data.name,
        "category_id": data.category_select,
        "city_id": data.city_select,    
        "currency_id": data.value_select,
        "incoterm_id": data.incoterm_select,
        "delivery_term_id": data.delivery_select,
            
        }

        await store.dispatch(Actions.CUST_ADD_PRICE_INDEX, db_data).then(({ data }) => {
        
        if (data)
        {
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "Price index Updated successfully .",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editPriceModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
        })
      .catch(({ response }) => {
        console.log(response);        
      });
    };

   const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setPriceData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });  
    }
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      setCurrencyData,
      setDeliveryData,
      setIncotermData,
      setParentCatData,
      setChildCatData,
      setCityData,
      city,
      parentcat,
      childcat,
      incoterm,
      delivery,
      currency,
      editPriceModalRef,
    };
  },
});
