
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import EditPriceIndexModal from "@/components/modals/forms/EditPriceIndexModal.vue";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";

export default defineComponent({
  props: 
  {
      id: { required: true }
  },
  name: "apps-price-details",
  components: {
   EditPriceIndexModal
  },
  methods: {
    
  },
  setup(props) {

    var ids = ref(props.id);
    const store = useStore();
    const loadingData = ref<boolean>(true);

   interface ViewPriceDetails {
      price_template_id,
      template_name: string,
      category_name: string,
      city: string,
      state: string,
      country: string,
      active : {
        label :string;
        color :string;
      }
    };    

     interface ViewPrice {
      reading_price,
      uom_id,
      price_difference,
      price_up_down,
      daily_reading_id,
      price_template_id,
      reading_date
    };
    
    var resultsView = ref<ViewPriceDetails>();
    var resultsView1 = ref<Array<ViewPrice>>([]);
    resultsView.value = {
      price_template_id: "",
      template_name: "",
      category_name: "",
      city:"",
      state:"",
      country:"",
      active: {
        label: "",
        color: "",
      }
    }

    const getGradeDetails = async () => {
     
      try {

      var values = { "price_template_id"  :  props.id}

      await store.dispatch(Actions.CUST_PRICE_EDIT, values).then(({ data }) => {

        var status_label = ""
        var status_color = ""
        var active_label = ""
        var active_color = ""

        if (data.active){
            active_label = "Yes";
            active_color = "success";                  
        }else{
            active_label = "No";
            active_color = "danger";
        }
     
        resultsView.value = {
          price_template_id: data.price_template_id,
          template_name: data.template_name,
          category_name: data.category_name,
          city: data.city,
          state: data.state,
          country: data.country,
          active: {
            label: active_label,
            color: active_color
          },
        }
      console.log(resultsView)
              

      })
      .catch(({ response }) => {

        console.log(response);

      });
        
      } catch (e) {
        console.log(e);
      }
    };  
    
    var paginationData = ref({})
    
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    const refreshData =() => {      
      paginationData.value['activePage']  = 1;      
      getPriceReadingDetails()
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
      getPriceReadingDetails()
    }
    
    
     var tableData = ref<Array<ViewPrice>>([]);
  
     const getPriceReadingDetails = async () => {
       // loadingData.value = true;

     try {

      var values = { 
        "price_template_id"  :  props.id,
        "page"  : parseInt(paginationData.value['activePage']), 
        "records_per_page" : parseInt(paginationData.value['perPage']),
        "active":1,
        "sort":"asc"
        }

      await store.dispatch(Actions.CUST_PRICE_READING_LIST, values).then(({ data }) => {

        console.log(data);
          
          tableData.value = ([]);

          // set pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          //endpagination
          console.log("paginationData.value")
          console.log(paginationData.value)
          var resultsM = ref<Array<ViewPrice>>([])
          var status_label = ""
          var status_color = ""
          var active_label = ""
          var active_color = ""     
        for (let j = 0; j < data.result_list.length; j++) {

            if (data.result_list[j]['active']){
                active_label = "Yes";
                active_color = "success";                  
            }else{
                active_label = "No";
                active_color = "danger";
            }

            resultsView1.value = Array({
              uom_id : data.uom_id,
              price_difference : data.result_list[j]['price_difference'],
              price_up_down : data.result_list[j]['price_up_down'],
              daily_reading_id : data.result_list[j]['daily_reading_id'],
              price_template_id : data.result_list[j]['price_template_id'],
              reading_date: data.result_list[j]['reading_date'],
              reading_price: data.result_list[j]['reading_price'],
          })
          tableData.value.splice(j, resultsView1.value.length, ...resultsView1.value); 
        }
          loadingData.value = false;

        }).catch(({ response }) => {
          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };
    
    
    
    onMounted( async () => {
      await getGradeDetails();
      await getPriceReadingDetails()
      setCurrentPageBreadcrumbs("Price Template Details", ["Price Template"]);
    });


    return {
      resultsView,
      resultsView1,
      tableData,
      paginationData,
      loadingData,
      changePageChange,
      getPriceReadingDetails,
      refreshData,
      ids,
    };

  }
});
